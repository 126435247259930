import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-fluid p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-4" }
const _hoisted_3 = { for: "active" }
const _hoisted_4 = {
  key: 0,
  class: "p-field p-col-4"
}
const _hoisted_5 = { for: "mode" }
const _hoisted_6 = {
  key: 1,
  class: "p-field p-col-4"
}
const _hoisted_7 = { for: "minInputLength" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = {
  key: 2,
  class: "p-field p-col-6"
}
const _hoisted_10 = { for: "tableId" }
const _hoisted_11 = {
  key: 0,
  class: "p-error"
}
const _hoisted_12 = {
  key: 3,
  class: "p-field p-col-6"
}
const _hoisted_13 = { for: "resultKeyColumnId" }
const _hoisted_14 = {
  key: 0,
  class: "p-error"
}
const _hoisted_15 = {
  key: 4,
  class: "p-field p-col-12"
}
const _hoisted_16 = { for: "searchColumnIds" }
const _hoisted_17 = {
  key: 0,
  class: "p-error"
}
const _hoisted_18 = {
  key: 5,
  class: "p-field p-col-12"
}
const _hoisted_19 = { for: "resultValueColumnIds" }
const _hoisted_20 = {
  key: 0,
  class: "p-error"
}
const _hoisted_21 = {
  key: 6,
  class: "p-field p-col-6"
}
const _hoisted_22 = { for: "ignoreInputValue" }
const _hoisted_23 = {
  key: 7,
  class: "p-field p-col-6"
}
const _hoisted_24 = { for: "maxLookupResultValues" }
const _hoisted_25 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Active')), 1),
      _createVNode(_component_Dropdown, {
        id: "active",
        modelValue: _ctx.value.active,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.active) = $event)),
        options: _ctx.dbLookupActive,
        optionValue: "id",
        optionLabel: "name",
        onChange: _ctx.update
      }, null, 8, ["modelValue", "options", "onChange"])
    ]),
    (_ctx.value.active === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.DbLookupAllowCustomValues')), 1),
          _createVNode(_component_Dropdown, {
            id: "mode",
            modelValue: _ctx.value.allowCustomValues,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.allowCustomValues) = $event)),
            options: _ctx.dbLookupModes,
            optionValue: "id",
            optionLabel: "name"
          }, null, 8, ["modelValue", "options"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.value.active === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.MinInputLength')) + " *", 1),
          _createVNode(_component_InputNumber, {
            id: "minInputLength",
            modelValue: _ctx.value.minInputLength,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.minInputLength) = $event)),
            class: _normalizeClass({'p-invalid':_ctx.v$.minInputLength.$invalid && _ctx.showErrorMessage}),
            onChange: _ctx.update,
            required: "true"
          }, null, 8, ["modelValue", "class", "onChange"]),
          (_ctx.v$.minInputLength.$invalid && _ctx.showErrorMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.MinInputLength')})), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.value.active === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Table')) + " *", 1),
          _createVNode(_component_Dropdown, {
            id: "tableId",
            style: {"min-width":"15.625rem"},
            modelValue: _ctx.value.tableId,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.tableId) = $event)),
            options: _ctx.allTables,
            optionValue: "id",
            optionLabel: _ctx.getColumnLabel,
            filter: true,
            showClear: true,
            onChange: _ctx.onChangeTable,
            class: _normalizeClass({'p-invalid':_ctx.v$.tableId.$invalid && _ctx.showErrorMessage}),
            onShow: _ctx.showDropdownOverlay
          }, null, 8, ["modelValue", "options", "optionLabel", "onChange", "class", "onShow"]),
          (_ctx.v$.tableId.$invalid && _ctx.showErrorMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.Table')})), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.value.active === true && _ctx.value.tableId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.ResultKeyColumn')) + " *", 1),
          _createVNode(_component_Dropdown, {
            id: "resultKeyColumnId",
            style: {"min-width":"15.625rem"},
            modelValue: _ctx.value.resultKeyColumnId,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.resultKeyColumnId) = $event)),
            options: _ctx.allTableColumn,
            optionValue: "id",
            optionLabel: _ctx.getColumnLabel,
            filter: true,
            showClear: true,
            onChange: _ctx.update,
            class: _normalizeClass({'p-invalid':_ctx.v$.resultKeyColumnId.$invalid && _ctx.showErrorMessage}),
            onShow: _ctx.showDropdownOverlay
          }, null, 8, ["modelValue", "options", "optionLabel", "onChange", "class", "onShow"]),
          (_ctx.v$.resultKeyColumnId.$invalid && _ctx.showErrorMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_14, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.ResultKeyColumn')})), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.value.active === true && _ctx.value.tableId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.SearchColumns')) + " *", 1),
          _createVNode(_component_MultiSelect, {
            id: "searchColumnIds",
            style: {"min-width":"15.625rem"},
            modelValue: _ctx.value.searchColumnIds,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value.searchColumnIds) = $event)),
            options: _ctx.allTableColumn,
            optionValue: "id",
            optionLabel: _ctx.getColumnLabel,
            display: "chip",
            filter: true,
            onChange: _ctx.update,
            class: _normalizeClass({'p-invalid':_ctx.v$.searchColumnIds.$invalid && _ctx.showErrorMessage}),
            onShow: _ctx.showDropdownOverlay
          }, null, 8, ["modelValue", "options", "optionLabel", "onChange", "class", "onShow"]),
          (_ctx.v$.searchColumnIds.$invalid && _ctx.showErrorMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.SearchColumns')})), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.value.active === true && _ctx.value.tableId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.ResultValueColumns')) + " *", 1),
          _createVNode(_component_MultiSelect, {
            id: "resultValueColumnIds",
            style: {"min-width":"15.625rem"},
            modelValue: _ctx.value.resultValueColumnIds,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.value.resultValueColumnIds) = $event)),
            options: _ctx.allTableColumn,
            optionValue: "id",
            optionLabel: _ctx.getColumnLabel,
            display: "chip",
            filter: true,
            onChange: _ctx.update,
            class: _normalizeClass({'p-invalid':_ctx.v$.resultValueColumnIds.$invalid && _ctx.showErrorMessage}),
            onShow: _ctx.showDropdownOverlay
          }, null, 8, ["modelValue", "options", "optionLabel", "onChange", "class", "onShow"]),
          (_ctx.v$.resultValueColumnIds.$invalid && _ctx.showErrorMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_20, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.ResultValueColumns')})), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.value.active === true && _ctx.value.tableId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.IgnoreInputValue')), 1),
          _createVNode(_component_Dropdown, {
            id: "ignoreInputValue",
            modelValue: _ctx.value.ignoreInputValue,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.value.ignoreInputValue) = $event)),
            options: _ctx.dbLookupActive,
            optionValue: "id",
            optionLabel: "name",
            onChange: _ctx.update
          }, null, 8, ["modelValue", "options", "onChange"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.value.active === true && _ctx.value.tableId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
          _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.MaxLookupResultValues')), 1),
          _createVNode(_component_InputNumber, {
            id: "maxLookupResultValues",
            modelValue: _ctx.value.maxLookupResultValues,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.value.maxLookupResultValues) = $event)),
            max: 1000,
            onChange: _ctx.update
          }, null, 8, ["modelValue", "onChange"]),
          (_ctx.v$.maxLookupResultValues.notZeroValidation.$invalid && _ctx.showErrorMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_25, _toDisplayString(_ctx.$t('Forms.Val.InvalidNullEntry', { field: _ctx.$t('Squeeze.DocumentClasses.MaxLookupResultValues')})), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
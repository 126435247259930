import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-174025b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "createNewSearch" }
const _hoisted_4 = {
  key: 0,
  class: "p-field p-col-12"
}
const _hoisted_5 = { for: "allSearches" }
const _hoisted_6 = { class: "flex align-items-center country-item" }
const _hoisted_7 = {
  key: 1,
  class: "p-field p-col-12"
}
const _hoisted_8 = { for: "privateOrGlobal" }
const _hoisted_9 = {
  key: 2,
  class: "p-field p-col-12"
}
const _hoisted_10 = { for: "name" }
const _hoisted_11 = {
  key: 0,
  class: "p-error"
}
const _hoisted_12 = {
  key: 1,
  class: "p-field p-col-12"
}
const _hoisted_13 = { class: "flex align-items-center country-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.showSearchesOnly)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.Queue.General.Action')), 1),
            _createVNode(_component_Dropdown, {
              id: "createNewSearch",
              modelValue: _ctx.value.actionType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.actionType) = $event)),
              options: _ctx.createNewSearch,
              loading: _ctx.loading,
              optionValue: "id",
              optionLabel: "description",
              onChange: _ctx.onChangeSaveType
            }, null, 8, ["modelValue", "options", "loading", "onChange"])
          ]),
          (_ctx.value.actionType !== 'new')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Squeeze.Queue.General.Search')), 1),
                _createVNode(_component_Dropdown, {
                  id: "allSearches",
                  modelValue: _ctx.value.searchId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.searchId) = $event)),
                  options: _ctx.allSearchesGroupedFiltered,
                  loading: _ctx.loading,
                  optionValue: "id",
                  optionGroupLabel: "label",
                  optionGroupChildren: "items",
                  optionLabel: "description",
                  filter: true,
                  onChange: _ctx.onChangeSearch
                }, {
                  optiongroup: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", null, [
                        _createElementVNode("i", {
                          class: _normalizeClass(slotProps.option.icon)
                        }, null, 2),
                        _createTextVNode(" " + _toDisplayString(slotProps.option.label), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "loading", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.value.actionType !== 'delete' && _ctx.store.state.isAdminUser)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('Squeeze.Queue.General.Type')), 1),
                _createVNode(_component_Dropdown, {
                  id: "privateOrGlobal",
                  modelValue: _ctx.value.global,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.global) = $event)),
                  options: _ctx.privateOrGlobalSearch,
                  loading: _ctx.loading,
                  optionValue: "id",
                  optionLabel: "description",
                  onChange: _ctx.update
                }, null, 8, ["modelValue", "options", "loading", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.value.actionType !== 'delete')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.value.actionType === 'new' ? _ctx.$t('Squeeze.DocumentClasses.Name') : _ctx.$t('Squeeze.Queue.General.NewName')) + " *", 1),
                _createVNode(_component_InputText, {
                  id: "name",
                  modelValue: _ctx.v$.description.$model,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.description.$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$.description.$invalid && _ctx.showErrorMessage}),
                  onChange: _ctx.update,
                  required: "true",
                  autofocus: ""
                }, null, 8, ["modelValue", "class", "onChange"]),
                (_ctx.v$.description.required.$invalid && _ctx.showErrorMessage)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.Name')})), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_Listbox, {
            id: "allSearches",
            modelValue: _ctx.value.searchId,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.searchId) = $event)),
            options: _ctx.allSearchesGrouped,
            loading: _ctx.loading,
            optionValue: "id",
            optionGroupLabel: "label",
            optionGroupChildren: "items",
            optionLabel: "description",
            onChange: _ctx.onChangeSearchList,
            filter: true,
            listStyle: "max-height: 60vh"
          }, {
            optiongroup: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", null, [
                  _createElementVNode("i", {
                    class: _normalizeClass(slotProps.option.icon)
                  }, null, 2),
                  _createTextVNode(" " + _toDisplayString(slotProps.option.label), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options", "loading", "onChange"])
        ]))
  ]))
}
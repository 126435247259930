import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-fluid p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "sourceTableId" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Table')) + " *", 1),
      _createVNode(_component_Dropdown, {
        id: "sourceTableId",
        modelValue: _ctx.value.sourceTableId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.sourceTableId) = $event)),
        options: _ctx.masterDataTables,
        loading: _ctx.loading,
        class: _normalizeClass({'p-invalid':_ctx.v$.sourceTableId.$invalid && _ctx.showErrorMessage}),
        showClear: true,
        filter: true,
        optionValue: "id",
        optionLabel: "name",
        onChange: _ctx.update,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "loading", "class", "onChange", "onShow"]),
      (_ctx.v$.sourceTableId.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.Table')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
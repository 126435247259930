import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-139af2ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 p-ml-2 p-component" }
const _hoisted_2 = { class: "header p-component" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SystemImport = _resolveComponent("SystemImport")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_ConfigurationsDataList = _resolveComponent("ConfigurationsDataList")!
  const _component_Message = _resolveComponent("Message")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('Squeeze.System.Configurations')), 1),
      _createVNode(_component_TabView, {
        activeIndex: _ctx.activeTabOption,
        "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTabOption) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabOptions, (tab) => {
            return (_openBlock(), _createBlock(_component_TabPanel, {
              key: tab.value,
              disabled: tab.value === 'Export'
            }, {
              header: _withCtx(() => [
                _createElementVNode("i", {
                  class: _normalizeClass(tab.icon)
                }, null, 2),
                _createTextVNode(" " + _toDisplayString(tab.value), 1)
              ]),
              default: _withCtx(() => [
                (tab.value === 'Import')
                  ? (_openBlock(), _createBlock(_component_SystemImport, {
                      key: 0,
                      showErrorMessage: _ctx.showErrorMessage,
                      selectedFiles: _ctx.files,
                      fileType: _ctx.fileType,
                      onUpdate: _ctx.onUpdate,
                      onFileUploader: _ctx.fileUploader
                    }, null, 8, ["showErrorMessage", "selectedFiles", "fileType", "onUpdate", "onFileUploader"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["disabled"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["activeIndex"])
    ]),
    _createVNode(_component_ConfigurationsDataList, {
      fileData: _ctx.fileData,
      showDataList: _ctx.showDataList,
      dataList: _ctx.dataList,
      onOnCloseDialog: _ctx.onCloseDialog,
      onRemoveFiles: _ctx.removeFiles
    }, null, 8, ["fileData", "showDataList", "dataList", "onOnCloseDialog", "onRemoveFiles"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showProgressBar,
      closable: !_ctx.showProgressBar,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showProgressBar = false)),
      headerText: "System Backup",
      showSaveButton: false,
      showAbortButton: !_ctx.showProgressBar,
      abortKeydownEvent: _ctx.showProgressBar,
      abortButtonText: _ctx.$t('Squeeze.General.Abort')
    }, {
      content: _withCtx(() => [
        _createVNode(_component_Message, {
          severity: "info",
          closable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Squeeze.System.Info')), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('Squeeze.System.BackupMessage')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ProgressBar, {
          mode: "indeterminate",
          style: {"height":"6px"}
        })
      ]),
      _: 1
    }, 8, ["show", "closable", "showAbortButton", "abortKeydownEvent", "abortButtonText"])
  ], 64))
}
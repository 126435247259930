import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9af385c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 child-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JobRunsList = _resolveComponent("JobRunsList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_JobRunsList, {
      jobRuns: _ctx.jobRuns,
      loading: _ctx.loading,
      paginationInfo: _ctx.pagination,
      filterOptions: _ctx.filters,
      onOnPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event))),
      onOnFilter: _ctx.onFilter
    }, null, 8, ["jobRuns", "loading", "paginationInfo", "filterOptions", "onOnFilter"])
  ]))
}
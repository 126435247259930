import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39cbe99c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigator-header p-mb-3" }
const _hoisted_2 = { class: "title p-my-2" }
const _hoisted_3 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_4 = { class: "p-field p-col-12 p-sm-6 p-lg-6 p-xl-3" }
const _hoisted_5 = {
  key: 0,
  class: "date-item date-item-value"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "p-field p-col-12 p-sm-6 p-lg-6 p-xl-3" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "p-placeholder",
  "data-pc-section": "label"
}
const _hoisted_10 = { class: "p-field p-col-12 p-sm-6 p-lg-6 p-xl-2" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "p-placeholder",
  "data-pc-section": "label"
}
const _hoisted_13 = { class: "p-field p-col-12 p-sm-6 p-lg-6 p-xl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_InputIcon = _resolveComponent("InputIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_IconField = _resolveComponent("IconField")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ButtonGroup = _resolveComponent("ButtonGroup")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("Squeeze.Reports.FieldSelectionQuality")), 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Calendar, {
            id: "range",
            modelValue: _ctx.selectedDate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDate) = $event)),
            selectionMode: "range",
            dateFormat: "dd.mm.yy",
            manualInput: false,
            showIcon: true,
            placeholder: _ctx.datePlaceholder
          }, {
            value: _withCtx((slotProps) => [
              (slotProps.selectedDate)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", null, _toDisplayString(slotProps.selectedDate), 1)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(slotProps.placeholder), 1))
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_MultiSelect, {
            modelValue: _ctx.selectedDocumentClasses,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDocumentClasses) = $event)),
            options: _ctx.allDocumentClasses,
            optionValue: "id",
            optionLabel: "name",
            filter: true,
            onShow: _ctx.showDropdownOverlay
          }, {
            value: _withCtx((slotProps) => [
              (slotProps.value.length)
                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createTextVNode(_toDisplayString(_ctx.getOptionDcLabel(slotProps.value)), 1)
                  ])), [
                    [
                      _directive_tooltip,
                      slotProps.value ? _ctx.mapTooltipDcValues(slotProps.value) : '',
                      void 0,
                      { bottom: true }
                    ]
                  ])
                : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('Squeeze.Reports.SelectDocumentClasses')), 1))
            ]),
            option: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.option.name), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "onShow"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_MultiSelect, {
            modelValue: _ctx.selectedFieldNames,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedFieldNames) = $event)),
            options: _ctx.allFields.filter(field => field.useInReport),
            optionValue: "name",
            optionLabel: "name",
            filter: true,
            onShow: _ctx.showDropdownOverlay
          }, {
            value: _withCtx((slotProps) => [
              (slotProps.value.length)
                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createTextVNode(_toDisplayString(_ctx.getOptionFieldLabel(slotProps.value)), 1)
                  ])), [
                    [
                      _directive_tooltip,
                      slotProps.value ? slotProps.value.map(value => value).join(', ') : '',
                      void 0,
                      { bottom: true }
                    ]
                  ])
                : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('Squeeze.Reports.SelectFields')), 1))
            ]),
            option: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.option.name), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "onShow"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_IconField, null, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_InputIcon, {
                class: "mdi mdi-comment-question-outline",
                style: {"cursor":"pointer"}
              }, null, 512), [
                [
                  _directive_tooltip,
                  _ctx.$t('Squeeze.Reports.CreditorHelpText'),
                  void 0,
                  { top: true }
                ]
              ]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.creditorIds,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.creditorIds) = $event)),
                id: "creditorIds",
                type: "text",
                placeholder: _ctx.$t('Squeeze.Reports.Creditor')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_ButtonGroup, { class: "p-field p-col-12 p-sm-6 p-lg-6 p-xl-2" }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_Button, {
              style: {"flex":"0","padding":"0.714rem 1.714rem"},
              icon: "mdi mdi-filter-off-outline",
              onClick: _ctx.clearFilters
            }, null, 8, ["onClick"]), [
              [
                _directive_tooltip,
                _ctx.$t('Squeeze.General.ClearFilters'),
                void 0,
                { top: true }
              ]
            ]),
            _createVNode(_component_Button, {
              icon: "mdi mdi-filter-outline",
              label: _ctx.$t('Squeeze.Reports.Filter'),
              onClick: _ctx.filter
            }, null, 8, ["label", "onClick"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_DataTable, {
        value: _ctx.reportData,
        loading: _ctx.loading,
        style: {"min-height":"calc(100vh - 40rem)","max-height":"calc(100vh - 25rem)"},
        class: "p-datatable-sm report-table",
        columnResizeMode: "fit",
        autoLayout: true,
        showGridlines: "",
        scrollable: "",
        scrollHeight: "flex",
        paginator: true,
        rows: _ctx.tableListPagination.pageSize,
        paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        rowsPerPageOptions: [25,50,100],
        currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
        onPage: _ctx.onChangePage
      }, {
        loading: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
        ]),
        empty: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.Reports.Data')})), 1)
        ]),
        paginatorstart: _withCtx(() => []),
        paginatorend: _withCtx(() => []),
        default: _withCtx(() => [
          _createVNode(_component_ColumnGroup, { type: "header" }, {
            default: _withCtx(() => [
              _createVNode(_component_Row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.SelectedFields'),
                    rowspan: 3
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.FieldProcessedCorrectly'),
                    colspan: 2
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.FieldEmpty'),
                    colspan: 3
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.FieldInvalid'),
                    colspan: 3
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.Total'),
                    rowspan: 3
                  }, null, 8, ["header"])
                ]),
                _: 1
              }),
              _createVNode(_component_Row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.Value'),
                    field: "valid"
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.InPercent'),
                    field: "validPercentage"
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.Value'),
                    field: "empty"
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.InPercent'),
                    field: "emptyPercentage"
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.Alternatives'),
                    field: "emptyAlternatives"
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.Value'),
                    field: "invalid"
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.InPercent'),
                    field: "validPercentage"
                  }, null, 8, ["header"]),
                  _createVNode(_component_Column, {
                    header: _ctx.$t('Squeeze.Reports.Table.Alternatives'),
                    field: "invalidAlternatives"
                  }, null, 8, ["header"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "name",
            header: _ctx.$t('Squeeze.Reports.Table.SelectedFields'),
            style: {"min-width":"10rem"}
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "valid",
            header: _ctx.$t('Squeeze.Reports.Table.FieldProcessedCorrectly'),
            style: {"min-width":"5rem"}
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "validPercentage",
            header: _ctx.$t('Squeeze.Reports.Table.InPercent'),
            style: {"min-width":"5rem"}
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(_ctx.formatValue(slotProps.data.validPercentage)) + "% ", 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "empty",
            header: _ctx.$t('Squeeze.Reports.Table.FieldEmpty'),
            style: {"min-width":"5rem"}
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "emptyPercentage",
            header: _ctx.$t('Squeeze.Reports.Table.InPercent'),
            style: {"min-width":"5rem"}
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(_ctx.formatValue(slotProps.data.emptyPercentage)) + "% ", 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "emptyAlternatives",
            header: _ctx.$t('Squeeze.Reports.Table.Alternatives'),
            style: {"min-width":"10rem"}
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "invalid",
            header: _ctx.$t('Squeeze.Reports.Table.FieldInvalid'),
            style: {"min-width":"5rem"}
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "invalidPercentage",
            header: _ctx.$t('Squeeze.Reports.Table.InPercent'),
            style: {"min-width":"5rem"}
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(_ctx.formatValue(slotProps.data.invalidPercentage)) + "% ", 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "invalidAlternatives",
            header: _ctx.$t('Squeeze.Reports.Table.Alternatives'),
            style: {"min-width":"10rem"}
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "processed",
            header: _ctx.$t('Squeeze.Reports.Table.Total'),
            style: {"min-width":"5rem"}
          }, null, 8, ["header"])
        ]),
        _: 1
      }, 8, ["value", "loading", "rows", "currentPageReportTemplate", "onPage"])
    ])
  ], 64))
}
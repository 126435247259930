import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-component p-fluid p-formgrid p-grid" }
const _hoisted_2 = { class: "p-col-3" }
const _hoisted_3 = { style: {"font-size":"1rem"} }
const _hoisted_4 = { class: "p-field p-input-filled p-col-9" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "p-col-3" }
const _hoisted_7 = { style: {"font-size":"1rem"} }
const _hoisted_8 = { class: "p-field p-input-filled p-col-9" }
const _hoisted_9 = { class: "p-col-3" }
const _hoisted_10 = { style: {"font-size":"1rem"} }
const _hoisted_11 = { class: "p-field p-input-filled p-col-9" }
const _hoisted_12 = { class: "p-col-3" }
const _hoisted_13 = { style: {"font-size":"1rem"} }
const _hoisted_14 = { class: "p-col-9" }
const _hoisted_15 = { class: "p-field p-input-filled p-inputgroup" }
const _hoisted_16 = { class: "p-col-3" }
const _hoisted_17 = { style: {"font-size":"1rem"} }
const _hoisted_18 = { class: "p-col-9" }
const _hoisted_19 = { class: "p-field p-input-filled p-inputgroup" }
const _hoisted_20 = { class: "p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DocumentPositionTrainingTable = _resolveComponent("DocumentPositionTrainingTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.trainingKey.description), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputText, {
          type: "text",
          modelValue: _ctx.trainingKey.value.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.trainingKey.value.value) = $event)),
          disabled: ""
        }, null, 8, ["modelValue"]),
        (!(_ctx.trainingKey.value.value !== '' && _ctx.trainingKey.value.value !== null))
          ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.$t("Squeeze.Training.TrainingKey")), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("Squeeze.DocumentClasses.Table")), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Dropdown, {
          onChange: _ctx.getDataOfTraining,
          modelValue: _ctx.value.tableId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.tableId) = $event)),
          options: _ctx.tables,
          optionLabel: "description",
          optionValue: "id"
        }, null, 8, ["onChange", "modelValue", "options"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("Squeeze.Training.Column")), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_Dropdown, {
          onChange: _ctx.getDataOfTraining,
          modelValue: _ctx.value.columnId,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.columnId) = $event)),
          options: _ctx.columnValues,
          optionLabel: "description",
          optionValue: "id",
          filter: true,
          onShow: _ctx.showDropdownOverlay
        }, null, 8, ["onChange", "modelValue", "options", "onShow"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("Squeeze.Training.Region")), 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: _ctx.columnRegionValue,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.columnRegionValue) = $event)),
            disabled: ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_Button, {
            class: "active",
            icon: "mdi mdi-auto-fix",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onFocusFieldOfPositionTraining('columnRegion')))
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("Squeeze.Locators.Value")), 1)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: _ctx.value.valuePattern,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value.valuePattern) = $event)),
            onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onFocusFieldOfPositionTraining('valuePattern')))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_Button, {
            class: "active",
            icon: "mdi mdi-auto-fix",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onFocusFieldOfPositionTraining('valuePattern')))
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createVNode(_component_Button, {
        onClick: _ctx.trainPositionValues,
        class: "p-mr-2",
        label: _ctx.buttonTrainText ? _ctx.$t('Squeeze.Training.Train') : _ctx.$t('Squeeze.Training.Retrain'),
        disabled: !(_ctx.value.columnId !== 0 && _ctx.value.valuePattern !== '' && _ctx.trainingKey.value.value !== '' && _ctx.trainingKey.value.value !== null)
      }, null, 8, ["onClick", "label", "disabled"]),
      _createVNode(_component_Button, {
        onClick: _ctx.testingPositionValues,
        label: _ctx.$t('Squeeze.Training.Test'),
        disabled: _ctx.tablePositionTrainingValues.length < 1
      }, null, 8, ["onClick", "label", "disabled"])
    ]),
    _createVNode(_component_DocumentPositionTrainingTable, {
      tablePositionTrainingValues: _ctx.tablePositionTrainingValues,
      clickOnTestingButton: _ctx.clickOnTestingButton,
      loading: _ctx.loading,
      onChangeTrainingRow: _ctx.changeTrainingRow,
      onDeleteTrainingRow: _ctx.deleteTrainingRow,
      onOnMarkRegion: _ctx.onMarkRegion
    }, null, 8, ["tablePositionTrainingValues", "clickOnTestingButton", "loading", "onChangeTrainingRow", "onDeleteTrainingRow", "onOnMarkRegion"])
  ], 64))
}
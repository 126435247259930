import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-component p-fluid p-formgrid p-grid" }
const _hoisted_2 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_3 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_4 = { class: "p-field p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DocumentSearchMask = _resolveComponent("DocumentSearchMask")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('Freeze.SearchMask.Store')), 1),
      _createVNode(_component_Dropdown, {
        id: "active",
        modelValue: _ctx.selectedStore,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStore) = $event)),
        options: _ctx.stores,
        optionValue: "name",
        optionLabel: "desc",
        placeholder: _ctx.$t('Freeze.SearchMask.PleaseSelect'),
        filter: true,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectStore($event))),
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "placeholder", "onShow"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('Freeze.SearchMask.SearchMask')), 1),
      _createVNode(_component_Dropdown, {
        id: "active",
        modelValue: _ctx.selectedMask,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedMask) = $event)),
        options: _ctx.searchMasks,
        optionLabel: "desc",
        placeholder: _ctx.$t('Freeze.SearchMask.PleaseSelectSearchMask'),
        filter: true,
        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectSearchMask($event))),
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "placeholder", "onShow"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_DocumentSearchMask, {
        onSubmit: _ctx.submit,
        "search-mask": _ctx.searchMask
      }, null, 8, ["onSubmit", "search-mask"])
    ])
  ]))
}
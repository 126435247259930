import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    class: "p-component p-shadow-2",
    value: _ctx.tablePositionTrainingValues,
    autoLayout: true,
    rowHover: true,
    onRowClick: _ctx.onMarkRegion,
    loading: _ctx.loading
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.Validation.General.Position') })), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "id",
        header: "ID",
        headerClass: "header-center",
        style: {"width":"3rem","text-align":"center"}
      }),
      _createVNode(_component_Column, {
        field: "columnName",
        header: _ctx.$t('Squeeze.Training.Column')
      }, null, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "valuePattern",
        header: _ctx.$t('Squeeze.Locators.PatternValue')
      }, null, 8, ["header"]),
      (_ctx.clickOnTestingButton)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            field: "columnHits",
            header: "Hits",
            headerClass: "header-center",
            style: {"width":"3rem","text-align":"center"}
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Column, { style: {"min-width":"8rem","text-align":"right"} }, {
        body: _withCtx((slotProps) => [
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-pencil-outline",
            class: "p-button p-component p-button-icon-only p-button-lg p-button-rounded p-button-success p-button-text",
            onClick: ($event: any) => (_ctx.changeTrainingRow(slotProps.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Locators.Edit')]
          ]),
          _withDirectives(_createVNode(_component_Button, {
            icon: "mdi mdi-delete-outline",
            class: "p-button p-button-rounded p-button-danger p-button-text deleteButtonRow",
            onClick: ($event: any) => (_ctx.deleteTrainingRow(slotProps.data))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "onRowClick", "loading"]))
}
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da13ed0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell" }
const _hoisted_2 = { class: "column-cell" }
const _hoisted_3 = { class: "column-cell" }
const _hoisted_4 = { class: "column-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_UserTakeOverRolesView = _resolveComponent("UserTakeOverRolesView")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      value: _ctx.users,
      loading: _ctx.loading,
      class: "p-datatable-sm p-datatable-documents",
      autoLayout: true,
      columnResizeMode: "fit",
      selection: _ctx.selection,
      "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selection) = $event)),
      selectionMode: _ctx.selectionMode,
      onRowSelect: _ctx.onRowSelect,
      paginator: true,
      rows: _ctx.tableListPagination.pageSize,
      paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
      rowsPerPageOptions: [25,50,100],
      currentPageReportTemplate: '{first} ' + _ctx.$t('Squeeze.General.To') + ' {last} ' + _ctx.$t('Squeeze.General.Of') + ' {totalRecords}  ',
      scrollable: true,
      scrollHeight: "flex",
      rowHover: true,
      onPage: _ctx.onChangePage,
      removableSort: true,
      filterDisplay: "row",
      filters: _ctx.filters,
      "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
      onFilter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFilter($event)))
    }, {
      loading: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
      ]),
      empty: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.UserManagement.User') })), 1)
      ]),
      paginatorstart: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('Squeeze.General.NewEntry'),
          type: "button",
          icon: "mdi mdi-plus",
          class: "p-button",
          onClick: _ctx.onNewEntryClick
        }, null, 8, ["label", "onClick"]),
        (_ctx.showBtnClearFilter)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.$t('Squeeze.General.ClearFilters'),
              type: "button",
              icon: "mdi mdi-filter-off-outline",
              class: "p-button p-ml-2",
              onClick: _ctx.clearFilters
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      paginatorend: _withCtx(() => []),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "id",
          header: "ID",
          class: "id-column",
          filterField: "id",
          sortable: true,
          showFilterMenu: false,
          style: {"min-width":"4rem","max-width":"4rem"}
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.id), 1)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"3rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "login",
          filterField: "login",
          header: _ctx.$t('Squeeze.Login.Login'),
          sortable: true,
          showFilterMenu: false,
          style: {"min-width":"7rem"}
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(slotProps.data.login), 1)
            ])), [
              [
                _directive_tooltip,
                slotProps.data.login,
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"7rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "firstName",
          header: _ctx.$t('Squeeze.UserManagement.Firstname'),
          sortable: true,
          showFilterMenu: false,
          style: {"min-width":"7rem"}
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(slotProps.data.firstName), 1)
            ])), [
              [
                _directive_tooltip,
                slotProps.data.firstName,
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"7rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "lastName",
          header: _ctx.$t('Squeeze.UserManagement.Lastname'),
          sortable: true,
          showFilterMenu: false,
          style: {"min-width":"7rem"}
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createTextVNode(_toDisplayString(slotProps.data.lastName), 1)
            ])), [
              [
                _directive_tooltip,
                slotProps.data.lastName,
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"7rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "email",
          header: _ctx.$t('Squeeze.UserManagement.Email'),
          sortable: true,
          showFilterMenu: false,
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createTextVNode(_toDisplayString(slotProps.data.email), 1)
            ])), [
              [
                _directive_tooltip,
                slotProps.data.email,
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              style: {"min-width":"10rem"},
              class: "p-column-filter filter-field",
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"])
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "inheritedRoles",
          header: '',
          sortable: false,
          style: {"min-width":"4rem","max-width":"6rem"}
        }, {
          body: _withCtx((user) => [
            (user.data.inheritedRoles.length > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.UserManagement.Inherited')), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          class: "buttonColumn",
          style: {"min-width":"5rem","max-width":"5rem"}
        }, {
          body: _withCtx((user) => [
            (user.data.id !== 1)
              ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  type: "button",
                  icon: "mdi mdi-account-key-outline",
                  class: "p-button-lg p-button-rounded p-button-text",
                  onClick: ($event: any) => (_ctx.openTakOverRolesDialog(user.data.id))
                }, null, 8, ["onClick"])), [
                  [
                    _directive_tooltip,
                    _ctx.$t('Squeeze.UserManagement.TakeOverRoles'),
                    void 0,
                    { bottom: true }
                  ]
                ])
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_Button, {
              class: _normalizeClass([{ invisible: user.data.isInherited }, "p-button-lg p-button-rounded p-button-danger p-button-text"]),
              icon: "mdi mdi-delete-outline",
              onClick: ($event: any) => (_ctx.openDeleteDialog(user.data))
            }, null, 8, ["class", "onClick"]), [
              [
                _directive_tooltip,
                _ctx.$t('Squeeze.Validation.Buttons.Delete'),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "loading", "selection", "selectionMode", "onRowSelect", "rows", "currentPageReportTemplate", "onPage", "filters"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showTakeOverRolesDialog,
      onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showTakeOverRolesDialog = false)),
      onOnConfirm: _ctx.executeTakeOverRoles,
      loading: _ctx.loadingTakeOverRoles,
      headerText: _ctx.headerTextOfTakeOverRoles,
      showKeepDialogOpen: false
    }, {
      content: _withCtx(() => [
        _createVNode(_component_UserTakeOverRolesView, {
          userId: _ctx.usersTakeOverRoles.userIdTo,
          usersTakeOverRoles: _ctx.usersTakeOverRoles,
          showErrorMessage: _ctx.showValidationMessage,
          onUpdate: _ctx.onUpdate
        }, null, 8, ["userId", "usersTakeOverRoles", "showErrorMessage", "onUpdate"])
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "headerText"])
  ], 64))
}

import {defineComponent, onMounted, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {JobRun, PaginationDto} from "@dex/squeeze-client-ts";
import JobRunsList from "@/apps/administration/components/system/JobRunsList.vue";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import useSqueezeStore from "@/apps/squeeze/store";
import {TableSettings} from "@/util/TableSettings";
import {useRoute} from "vue-router";

export default defineComponent({
	name: 'JobRunsView',
	components: {
		JobRunsList,
	},
	props: {
		/** Currently active job */
		jobId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Job API endpoint */
		const jobService = ClientManager.getInstance().squeeze.jobs;

		/** Show loading? **/
		const loading = ref(false);

		/** Array with all job runs data **/
		const jobRuns = ref<JobRun[]>([]);

		/** Empty pagination object & Info / Options of pagination  */
		const pagination = reactive<PaginationDto>({pageSize: 25, page: 0, total: 0});

		/** Filters of jobRuns-Status */
		const filters = reactive<DocumentFilterObject>({
			"status": {value: '', matchMode: FilterMatchMode.EQUALS},
		});

		/** Current selected status */
		const status = ref<string | undefined>(undefined);

		/** Get all job runs data **/
		const getJobRuns = (status: string | undefined) => {
			loading.value = true;
			jobService.getJobRunsByJobId(props.jobId, status, pagination.page, pagination.pageSize)
				.then((data) => {
					if(data.elements) {
						jobRuns.value = data.elements;
					}
					Object.assign(pagination, data.pagination);
					pagination.pageSize = TableSettings.getTableListPagination(store, route.name);
				}).catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.ErrorDelete'), t('Squeeze.General.DeleteError') + ": " + error.message);
				}).finally(() => {
					loading.value = false;
				})
		}

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		/** Triggered when a page is changed */
		const onPage = (event: { page: number; rows: number }) => {
			pagination.page = event.page;
			pagination.pageSize = event.rows;

			if (status.value) {
				getJobRuns(status.value);
			} else {
				getJobRuns(undefined);
			}

			onChangePage(event);
		}

		/** Triggered when a status-filter is selected */
		const onFilter = (filterStatus: any) => {
			if (filterStatus.status.value) {
				status.value = filterStatus.status.value.toLowerCase();
			} else {
				status.value = undefined;
			}
			getJobRuns(status.value);
		}

		onMounted(() => {
			getJobRuns(undefined);
		});

		return {
			t, toast, loading, jobRuns, pagination, filters, status, getJobRuns, onPage, onFilter, onChangePage,
		}
	},
})


import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {PaginationDto, MasterDataColumn, KeyValueCollection, KeyValue, MasterDataColumnFilter} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import MasterDataRows from "@/apps/administration/components/masterdata/tabs/MasterDataRows.vue";
import MasterDataRowsForm from "@/apps/administration/components/masterdata/tabs/MasterDataRowsForm.vue";
import {DocumentFilterObject, Filter} from "@/apps/squeeze/interfaces/DocumentSearch";
import {TableSettings} from "@/util/TableSettings";
import {useRoute} from "vue-router";
import useSqueezeStore from "@/apps/squeeze/store";

export default defineComponent({
	name: "MasterDataRowsView",
	components: {
		DialogDelete, MasterDataRowsForm, EntryDialog, BlockUI, MasterDataRows,
	},
	props: {
		masterDataTableId: {
			type: Number,
			default: 0,
			required: true,
		},
		permissions: {
			type: Object,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Service for getting the locator-data */
		const masterDataService = ClientManager.getInstance().squeeze.masterData;

		/** List of all Columns */
		const masterDataColumns = ref<MasterDataColumn[]>([]);

		/** Should the Entry-Dialog for Columns be shown? */
		const showDialog = ref<boolean>(false);

		/** Should the reset dialog be shown? */
		const showResetDialog = ref<boolean>(false);

		/** List of rows */
		const masterDataRows = ref<any[]>([]);

		/** Row to edit */
		const masterDataRow = reactive<any>({});

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Empty pagination object & Info / Options of pagination  */
		const pagination = reactive<PaginationDto>({pageSize: 25, page: 0, total: 0});

		/** Is MasterDataRow changed? */
		const changeMasterDataRow = ref<boolean>(false);

		/** Default-Filter for Master Data in Syntax of Primevue-filters */
		const filters = ref<DocumentFilterObject>({});

		/** List of Search-Filters for Api-Request */
		const searchFilters = ref<MasterDataColumnFilter[]>([]);

		/** Indicates end of mounted */
		const initialLoaded = ref(false);

		/** Triggered on update of row-form */
		const onUpdate = (data: any) => {
			Object.assign(masterDataRow, data);
		}

		/** Default sorting of table */
		const tableSort = ref({
			fieldName: "id",
			sort: "ASC",
		})

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (masterDataRow.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.MasterData.Row') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.MasterData.Row') });
		});

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: any) => {
			Object.assign(masterDataRow, row);
			showDialog.value = true;
		}

		/**
		 * Reloads the table
		 */
		const reloadData = async (reloadColumns: boolean = true) => {
			loading.value = true;

			const promiseColumn = masterDataService.getMasterDataTableColumns(props.masterDataTableId);
			const promiseRows = masterDataService.getMasterDataTableRows(props.masterDataTableId, searchFilters.value, true, tableSort.value.fieldName, tableSort.value.sort, pagination.page, pagination.pageSize, false);

			// Wait until promises are finished
			await Promise.all([promiseColumn, promiseRows]).then(values => {
				if (reloadColumns) {
					masterDataColumns.value = values[0];

					masterDataColumns.value.forEach(column => {
						filters.value["" + column.id] = {
							value: "",
							matchMode: "cn",
						}
					});
				}

				masterDataRows.value = values[1].elements as any[];
				Object.assign(pagination, values[1].pagination);
				pagination.pageSize = TableSettings.getTableListPagination(store, route.name);
				initialLoaded.value = true;
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}


		/** Triggered when a filter has been entered */
		const onFilter = (tableFilters: DocumentFilterObject) => {
			const columnFilters: MasterDataColumnFilter[] = [];
			for(const id in tableFilters) {
				const fieldId = Number(id);
				const filter: Filter = tableFilters[id];
				const fieldValue = filter.value;
				const operator = filter.matchMode;

				if (fieldValue) {
					const masterDataFilter: MasterDataColumnFilter = {
						operand: operator,
						searchValue: fieldValue,
						columnId: fieldId,
						lookupFieldFilter: false,
					}

					columnFilters.push(masterDataFilter);
				}
			}

			searchFilters.value = columnFilters;
			reloadData();
		}

		/**
		 * Triggered when a search if fired
		 * @param fieldName
		 * @param sortDirection
		 */
		const onSort = (fieldName: string, sortDirection: number) => {
			if (fieldName) {
				const sortOrder = sortDirection === -1 ? "DESC": "ASC";
				tableSort.value.sort = sortOrder;
				tableSort.value.fieldName = fieldName;
			} else {
				tableSort.value.sort = "ASC";
				tableSort.value.fieldName = "id";
			}
			reloadData();
		}

		/**
		 * Get Type of current locator on load
		 */
		onMounted(() =>{
			reloadData();
		})

		/** Parses a Row so that Post/Put Request can be valid */
		const parseRowToKeyValueCollection = (masterDataRow: any[]) => {
			const keyValueCollection: KeyValueCollection = {
				keyValues: [],
			};

			for (const key in masterDataRow) {
				const value = masterDataRow[key];
				const keyValue: KeyValue = {};
				keyValue.key = key;
				keyValue.value = value;

				// If value is empty, don't add it to the array
				if (key.toLowerCase() === "id" && !value) {
					continue;
				}
				keyValueCollection.keyValues!.push(keyValue);
			}

			return keyValueCollection;
		}

		/**
		 * Saves a column
		 * @param keepDialogOpen
		 * @param event
		 */
		const saveMasterDataColumn = (keepDialogOpen: boolean = false, event: any = null) => {
			loadingDialog.value = true;
			loading.value = true;

			const keyValueCollection = parseRowToKeyValueCollection(masterDataRow);

			if (!keyValueCollection || !keyValueCollection.keyValues) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error'));
				return;
			}

			let promise = null;

			if (!masterDataRow.id) {
				promise = masterDataService.postMasterDataTableRow(props.masterDataTableId, keyValueCollection);
			} else {
				promise = masterDataService.putMasterDataTableRow(props.masterDataTableId, masterDataRow.id, keyValueCollection);
			}

			promise.then(async () => {
				if(!changeMasterDataRow.value) {
					await reloadData();
				} else {
					await reloadData(false);
				}

				if (!keepDialogOpen) {
					showDialog.value = false;
				} else {
					// set masterDataRow empty
					Object.keys(masterDataRow).map(key => {
						masterDataRow[key] = '';
						return {[key]: masterDataRow[key]};
					});
				}
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
				changeMasterDataRow.value = false;

				if (event) {
					// Notify any listeners in case they need to wait for the completion of this request
					document.dispatchEvent(new CustomEvent('reloadDone', { detail: event }));
				}
			})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: any) => {
			deleteDialog.value = true;
			Object.assign(masterDataRow, row);
		}

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		/** Triggered when a page is changed in the QueueView */
		const onPage = (event: { page: number; rows: number }) => {
			pagination.page = event.page;
			pagination.pageSize = event.rows;

			onChangePage(event);
			reloadData();
		}

		/**
		 * Deletes a Column
		 */
		const deleteEntry = () => {
			if (masterDataRow && masterDataRow.id) {
				loading.value = true;
				masterDataService.deleteMasterDataTableRow(props.masterDataTableId, masterDataRow.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/**
		 * Triggered when an input field changed
		 * @param event
		 */
		const onChangeInputField = (event: any) => {
			changeMasterDataRow.value = true;
			Object.assign(masterDataRow, event.newData);
			saveMasterDataColumn(false, event);
		}

		/** Export/Download the master data */
		const startExportOfMasterData = () => {
			loading.value = true;
			masterDataService.getMasterDataTableCSV(props.masterDataTableId)
				.then((response: Response) => {
					window.open(response.url);
				})
				.catch((response: Response) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		return {
			loading,
			loadingDialog,
			showDialog,
			headerText,
			filters,
			initialLoaded,
			masterDataColumns,
			deleteDialog,
			showResetDialog,
			masterDataRow,
			masterDataRows,
			pagination,
			changeMasterDataRow,
			onPage,
			deleteEntry,
			openDeleteDialog,
			onUpdate,
			onEntrySelect,
			saveMasterDataColumn,
			onChangeInputField,
			onFilter,
			onSort,
			startExportOfMasterData,
		};
	},
});


import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "masterDataColumnId" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = { for: "valueFieldId" }
const _hoisted_7 = {
  key: 0,
  class: "p-field p-col-12"
}
const _hoisted_8 = { for: "valueFieldId" }
const _hoisted_9 = {
  key: 0,
  class: "p-error"
}
const _hoisted_10 = { class: "p-field p-col-12" }
const _hoisted_11 = { for: "valueFieldId" }
const _hoisted_12 = {
  key: 3,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.DatabaseColumn')), 1),
      _createVNode(_component_Dropdown, {
        id: "masterDataColumnId",
        modelValue: _ctx.value.masterDataColumnId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.masterDataColumnId) = $event)),
        options: _ctx.masterDataTableColumns,
        loading: _ctx.loading,
        optionValue: "id",
        optionLabel: "description",
        onChange: _ctx.update,
        placeholder: _ctx.$t('Squeeze.General.None'),
        filter: true,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "loading", "onChange", "placeholder", "onShow"]),
      (_ctx.v$.masterDataColumnId.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.DatabaseColumn')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Operand')), 1),
      _createVNode(_component_Dropdown, {
        id: "valueFieldId",
        modelValue: _ctx.value.operand,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.operand) = $event)),
        options: _ctx.operands,
        loading: _ctx.loading,
        optionValue: "id",
        optionLabel: "name",
        onChange: _ctx.update
      }, null, 8, ["modelValue", "options", "loading", "onChange"])
    ]),
    (_ctx.showTableRows)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.FilterType')), 1),
          _createVNode(_component_Dropdown, {
            id: "valueFieldId",
            modelValue: _ctx.value.rowBasedFilter,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.rowBasedFilter) = $event)),
            options: _ctx.fieldTypes,
            loading: _ctx.loading,
            optionValue: "id",
            optionLabel: "name",
            onChange: _ctx.onChangeRowBaseFilter
          }, null, 8, ["modelValue", "options", "loading", "onChange"]),
          (_ctx.v$.masterDataColumnId.$invalid && _ctx.showErrorMessage)
            ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.FilterType')})), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.Field')), 1),
      (_ctx.value.rowBasedFilter === false)
        ? (_openBlock(), _createBlock(_component_Dropdown, {
            key: 0,
            id: "valueFieldId",
            modelValue: _ctx.value.valueFieldId,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.valueFieldId) = $event)),
            options: _ctx.documentClassFields,
            loading: _ctx.loading,
            optionValue: "id",
            optionLabel: "description",
            onChange: _ctx.update,
            placeholder: _ctx.$t('Squeeze.General.None'),
            filter: true,
            onShow: _ctx.showDropdownOverlay
          }, null, 8, ["modelValue", "options", "loading", "onChange", "placeholder", "onShow"]))
        : (_ctx.value.rowBasedFilter === true)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 1,
              id: "valueFieldId",
              modelValue: _ctx.value.valueFieldId,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.valueFieldId) = $event)),
              options: _ctx.tableColumns,
              loading: _ctx.loading,
              optionValue: "id",
              optionLabel: "description",
              onChange: _ctx.update,
              placeholder: _ctx.$t('Squeeze.General.None'),
              filter: true,
              onShow: _ctx.showDropdownOverlay
            }, null, 8, ["modelValue", "options", "loading", "onChange", "placeholder", "onShow"]))
          : (_openBlock(), _createBlock(_component_Dropdown, {
              key: 2,
              id: "valueFieldId",
              modelValue: _ctx.value.valueFieldId,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value.valueFieldId) = $event)),
              options: [],
              loading: _ctx.loading,
              optionValue: "id",
              optionLabel: "description",
              onChange: _ctx.update,
              placeholder: _ctx.$t('Squeeze.General.None'),
              filter: true,
              onShow: _ctx.showDropdownOverlay
            }, null, 8, ["modelValue", "loading", "onChange", "placeholder", "onShow"])),
      (_ctx.v$.valueFieldId.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.DocumentClasses.Field')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!

  return (_openBlock(), _createBlock(_component_Breadcrumb, {
    home: _ctx.home,
    model: _ctx.breadCrumbs
  }, {
    item: _withCtx(({item}) => [
      _createVNode(_component_router_link, {
        to: item.to
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", {
            class: _normalizeClass(item.icon)
          }, [
            (item.label && !item.customTranslation)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.Breadcrumbs.' + item.label)), 1)
                ], 64))
              : _createCommentVNode("", true),
            (item.customTranslation && _ctx.route.path.includes('masterdata') && (_ctx.route.params.masterDataTableId === 'undefined' || _ctx.route.params.masterDataTableId === '0'))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.Breadcrumbs.' + _ctx.selectedItemLabel)), 1)
                ], 64))
              : (item.customTranslation && _ctx.route.path.includes('masterdata') && _ctx.route.params.masterDataTableId)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createTextVNode(_toDisplayString(_ctx.selectedItemLabel), 1)
                  ], 64))
                : _createCommentVNode("", true),
            (item.customTranslation && _ctx.route.path.includes('roles') && _ctx.route.params.roleId === '0')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.Breadcrumbs.' + _ctx.selectedItemLabel)), 1)
                ], 64))
              : (item.customTranslation && _ctx.route.path.includes('roles') && _ctx.route.params.roleId)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                    _createTextVNode(_toDisplayString(_ctx.selectedItemLabel), 1)
                  ], 64))
                : _createCommentVNode("", true),
            (item.customTranslation && _ctx.route.path.includes('jobManagement') && _ctx.route.params.jobId === 'undefined')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.Breadcrumbs.' + _ctx.selectedItemLabel)), 1)
                ], 64))
              : (item.customTranslation && _ctx.route.path.includes('jobManagement') && _ctx.route.params.jobId)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                    _createTextVNode(_toDisplayString(_ctx.selectedItemLabel), 1)
                  ], 64))
                : _createCommentVNode("", true)
          ], 2)
        ]),
        _: 2
      }, 1032, ["to"]),
      (item.customTranslation && _ctx.route.path.includes('bc') && _ctx.route.params.batchClassId === 'undefined')
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: _normalizeClass(item.icon)
          }, _toDisplayString(_ctx.$t('Squeeze.Breadcrumbs.' + _ctx.selectedItemLabel)), 3))
        : (item.customDropdown && _ctx.route.path.includes('bc') && _ctx.route.params.batchClassId)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 1,
              modelValue: _ctx.selectedItem,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
              options: _ctx.batchClasses,
              optionLabel: "description",
              optionValue: "id",
              loading: _ctx.loading,
              filter: true,
              onChange: _ctx.changeItem,
              onShow: _ctx.showDropdownOverlay
            }, null, 8, ["modelValue", "options", "loading", "onChange", "onShow"]))
          : _createCommentVNode("", true),
      (item.customTranslation && _ctx.route.path.includes('documentclasses') && _ctx.route.params.documentClassId === '0')
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            class: _normalizeClass(item.icon)
          }, _toDisplayString(_ctx.$t('Squeeze.Breadcrumbs.' + _ctx.selectedItemLabel)), 3))
        : (item.customDropdown && _ctx.route.path.includes('documentclasses') && _ctx.route.params.documentClassId)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 3,
              modelValue: _ctx.selectedItem,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedItem) = $event)),
              options: _ctx.documentClasses,
              optionLabel: "description",
              optionValue: "id",
              loading: _ctx.loading,
              filter: true,
              onChange: _ctx.changeItem,
              onShow: _ctx.showDropdownOverlay
            }, null, 8, ["modelValue", "options", "loading", "onChange", "onShow"]))
          : _createCommentVNode("", true),
      (item.customTranslation && _ctx.route.path.includes('locators') && _ctx.route.params.locatorId === '0')
        ? (_openBlock(), _createElementBlock("a", {
            key: 4,
            class: _normalizeClass(item.icon)
          }, _toDisplayString(_ctx.$t('Squeeze.Breadcrumbs.' + _ctx.selectedItemLabel)), 3))
        : (item.customDropdown && _ctx.route.path.includes('locators') && _ctx.route.params.locatorId)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 5,
              modelValue: _ctx.selectedItem,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItem) = $event)),
              options: _ctx.locators,
              optionLabel: "description",
              optionValue: "id",
              loading: _ctx.loading,
              filter: true,
              onChange: _ctx.changeItem,
              onShow: _ctx.showDropdownOverlay
            }, null, 8, ["modelValue", "options", "loading", "onChange", "onShow"]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["home", "model"]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ba3a7cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fluid p-formgrid p-grid p-input-filled" }
const _hoisted_2 = { class: "p-field p-col-12" }
const _hoisted_3 = { for: "settingName" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "p-field p-col-12" }
const _hoisted_6 = {
  key: 5,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Squeeze.BatchClasses.SettingName')) + " *", 1),
      _createVNode(_component_Dropdown, {
        id: "settingName",
        style: {"min-width":"15.625rem"},
        modelValue: _ctx.value.settingName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.settingName) = $event)),
        options: _ctx.allBatchClassSettings,
        optionValue: "settingName",
        optionLabel: "settingName",
        onChange: _ctx.onChangeSettingName,
        disabled: _ctx.disableSettingName,
        class: _normalizeClass({'p-invalid':_ctx.v$.settingName.$invalid && _ctx.showErrorMessage}),
        filter: true,
        onShow: _ctx.showDropdownOverlay
      }, null, 8, ["modelValue", "options", "onChange", "disabled", "class", "onShow"]),
      (_ctx.v$.settingName.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.BatchClasses.SettingName')})), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('Squeeze.BatchClasses.Value')) + " *", 1),
      (_ctx.schema.type === 'string' && !_ctx.schema.enum)
        ? (_openBlock(), _createBlock(_component_InputText, {
            key: 0,
            modelValue: _ctx.value.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.value) = $event)),
            required: "true",
            onInput: _ctx.update,
            placeholder: _ctx.schema.example ? _ctx.schema.example : ''
          }, null, 8, ["modelValue", "onInput", "placeholder"]))
        : (_ctx.schema.type === 'integer' && !_ctx.schema.enum)
          ? (_openBlock(), _createBlock(_component_InputText, {
              key: 1,
              modelValue: _ctx.value.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.value) = $event)),
              required: "true",
              onInput: _ctx.update,
              placeholder: _ctx.schema.example ? _ctx.schema.example : '',
              class: "amount-field",
              onKeydown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkIntegerValue($event)))
            }, null, 8, ["modelValue", "onInput", "placeholder"]))
          : (_ctx.schema.type === 'boolean' && !_ctx.schema.enum)
            ? (_openBlock(), _createBlock(_component_Dropdown, {
                key: 2,
                style: {"min-width":"15.625rem"},
                modelValue: _ctx.value.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.value) = $event)),
                options: _ctx.listOfBooleans,
                optionValue: "id",
                optionLabel: "name",
                onChange: _ctx.update,
                class: _normalizeClass({'p-invalid':_ctx.v$.value.$invalid && _ctx.showErrorMessage}),
                onShow: _ctx.showDropdownOverlay
              }, null, 8, ["modelValue", "options", "onChange", "class", "onShow"]))
            : (_ctx.schema.enum)
              ? (_openBlock(), _createBlock(_component_Dropdown, {
                  key: 3,
                  style: {"min-width":"15.625rem"},
                  modelValue: _ctx.value.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value.value) = $event)),
                  optionLabel: _ctx.getSchemaEnums,
                  options: _ctx.schema.enum,
                  onChange: _ctx.update,
                  class: _normalizeClass({'p-invalid':_ctx.v$.value.$invalid && _ctx.showErrorMessage}),
                  filter: true,
                  onShow: _ctx.showDropdownOverlay
                }, null, 8, ["modelValue", "optionLabel", "options", "onChange", "class", "onShow"]))
              : (_openBlock(), _createBlock(_component_InputText, {
                  key: 4,
                  modelValue: _ctx.value.value,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.value.value) = $event)),
                  required: "true",
                  onInput: _ctx.update
                }, null, 8, ["modelValue", "onInput"])),
      (_ctx.value.settingName && _ctx.v$.value.$invalid && _ctx.showErrorMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.$t('Forms.Val.MissingRequired', { field: _ctx.$t('Squeeze.BatchClasses.Value')})), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b357ca3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigator-header p-mb-3" }
const _hoisted_2 = { class: "title p-my-2" }
const _hoisted_3 = { class: "p-fluid p-component p-formgrid p-grid p-input-filled" }
const _hoisted_4 = { class: "p-field p-col-6 p-sm-4" }
const _hoisted_5 = {
  key: 0,
  class: "country-item country-item-value"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "country-item" }
const _hoisted_8 = { class: "p-field p-col-6 p-sm-4" }
const _hoisted_9 = {
  key: 0,
  class: "month-item month-item-value"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "month-item" }
const _hoisted_12 = { class: "p-col-12" }
const _hoisted_13 = {
  id: "processedReportChart",
  ref: "canvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("Squeeze.Reports.Processed")), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.selectedYear,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedYear) = $event)),
          options: _ctx.years,
          optionLabel: "name",
          filter: true,
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeYears($event))),
          onShow: _ctx.showDropdownOverlay
        }, {
          value: _withCtx((slotProps) => [
            (slotProps.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", null, _toDisplayString(slotProps.value.name), 1)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(slotProps.placeholder), 1))
          ]),
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, _toDisplayString(slotProps.option.name), 1)
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "options", "onShow"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.selectedMonth,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedMonth) = $event)),
          options: _ctx.months,
          placeholder: _ctx.$t('Squeeze.Reports.PlaceholderMonth'),
          optionLabel: "name",
          showClear: true,
          filter: true,
          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChangeMonths($event))),
          onShow: _ctx.showDropdownOverlay
        }, {
          value: _withCtx((slotProps) => [
            (slotProps.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", null, _toDisplayString(slotProps.value.name), 1)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(slotProps.placeholder), 1))
          ]),
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", null, _toDisplayString(slotProps.option.name), 1)
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "options", "placeholder", "onShow"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("canvas", _hoisted_13, null, 512)
      ])
    ])
  ], 64))
}
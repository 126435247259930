import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c837524"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-component" }
const _hoisted_2 = { class: "p-fluid p-formgrid p-grid" }
const _hoisted_3 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_4 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_5 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_6 = { class: "p-field p-col-12" }
const _hoisted_7 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_8 = { class: "p-field p-input-filled p-col-12" }
const _hoisted_9 = {
  key: 0,
  class: "p-fluid p-formgrid p-grid fields-scroll"
}
const _hoisted_10 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Message = _resolveComponent("Message")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('Freeze.SearchMask.Store')), 1),
          _createVNode(_component_Dropdown, {
            id: "active",
            modelValue: _ctx.selectedStore,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStore) = $event)),
            options: _ctx.stores,
            optionValue: "name",
            optionLabel: "desc",
            placeholder: _ctx.$t('Freeze.SearchMask.PleaseSelect'),
            filter: true,
            showClear: false,
            onShow: _ctx.showDropdownOverlay
          }, null, 8, ["modelValue", "options", "placeholder", "onShow"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('Freeze.General.FieldList')), 1),
          _createVNode(_component_Dropdown, {
            id: "active",
            modelValue: _ctx.selectedMask,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedMask) = $event)),
            options: _ctx.searchMasks,
            optionLabel: "desc",
            placeholder: _ctx.$t('Freeze.SearchMask.PleaseSelectSearchMask'),
            filter: true,
            showClear: false,
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectSearchMask($event))),
            onShow: _ctx.showDropdownOverlay
          }, null, 8, ["modelValue", "options", "placeholder", "onShow"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_FileUpload, {
            onSelect: _ctx.onSelectFiles,
            name: "documentFile",
            customUpload: true,
            onUploader: _ctx.fileUploader,
            multiple: true,
            maxFileSize: 100000000,
            showCancelButton: true,
            uploadFiles: _ctx.files,
            cancelLabel: _ctx.$t('Squeeze.General.Clear'),
            chooseLabel: _ctx.$t('Freeze.General.ChooseFiles'),
            customProgress: _ctx.progress,
            fileContentHeight: '15.625rem',
            uploadLabel: _ctx.uploadLabel,
            alwaysEnableUploadButton: true,
            onClear: _ctx.clearFiles,
            onRemoveFile: _ctx.removeFile,
            showUploadButton: false,
            invalidFileSizeMessage: '{0}: ' + _ctx.$t('Squeeze.Validation.General.InvalidFileSize') + ' ' + '{1}'
          }, null, 8, ["onSelect", "onUploader", "uploadFiles", "cancelLabel", "chooseLabel", "customProgress", "uploadLabel", "onClear", "onRemoveFile", "invalidFileSizeMessage"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Button, {
            label: _ctx.uploadLabel,
            onClick: _ctx.fileUploader
          }, null, 8, ["label", "onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.fields && _ctx.fields.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: field.name,
                  class: "p-field p-input-filled p-col-6"
                }, [
                  _createElementVNode("label", {
                    for: 'input-' + field.name
                  }, _toDisplayString(field.desc), 9, _hoisted_10),
                  _createVNode(_component_InputText, {
                    modelValue: field.value,
                    "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                    id: 'input-' + field.name
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "id"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showMessage,
      onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showMessage = false)),
      headerText: _ctx.$t('Squeeze.General.Upload'),
      showSaveButton: false,
      showAbortButton: false
    }, {
      content: _withCtx(() => [
        _createVNode(_component_Message, {
          severity: _ctx.messageSeverity,
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.messageText), 1)
          ]),
          _: 1
        }, 8, ["severity"])
      ]),
      _: 1
    }, 8, ["show", "headerText"])
  ]))
}